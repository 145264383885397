import { CSSProperties, ImgHTMLAttributes, useEffect, useRef } from 'react';
import lozad from 'lozad';
import { STATIC_ASSETS_URL } from '@/constants/common';

const LazyImg: React.FC<
  ImgHTMLAttributes<HTMLImageElement> & { overlayStyle?: CSSProperties }
> = ({ src, alt = '', overlayStyle, ...restProps }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const { width, height } = restProps;

  useEffect(() => {
    const observer = lozad(imgRef.current, {
      load: function (img: HTMLImageElement) {
        img.src = `${STATIC_ASSETS_URL}${img.dataset.src}`;
        delete img.dataset.src;
      },
    });
    observer.observe();
  }, []);

  return (
    <span style={{ width, height, display: 'inline-block', ...overlayStyle }}>
      <img ref={imgRef} data-src={src} {...restProps} alt={alt} />
    </span>
  );
};

export default LazyImg;
